











































































import { Component, Prop } from 'vue-property-decorator';
import { AuthorizationMixin } from '@/views/mixin';
import FileDownload from '@/modules/fileManagement/components/FileDownload.vue';
import ViewFileDialog from '@/modules/fileManagement/components/ViewFileDialog.vue';
import { MimeType, SearchFile } from '../types';
import AppIterator from '@/components/AppIterator.vue';
import CardRow from '@/components/CardRow.vue';

@Component({
  components: {
    CardRow,
    AppIterator,
    ViewFileDialog,
    FileDownload,
  }
})
export default class Search extends AuthorizationMixin {
  @Prop({ type: Array, default: () => [] }) items!: SearchFile[];
  @Prop({ type: Function, required: true }) searchFiles!: () => void;

  viewFile: SearchFile | null = null;

  supportedViews: MimeType[] = [
    MimeType.JPG,
    MimeType.PDF,
    MimeType.PNG
  ];

  pagination = {
    sortBy: 'filename',
    itemsPerPage: -1
  };

  get keys() {
    return [
      { text: this.$i18n.t('common.filename'), value: 'filename' },
      { text: this.$i18n.t('common.folder'), value: 'folder' },
      { text: this.$i18n.t('common.conservatee'), value: 'conservatee' },
      ...(this.isAdmin() ? [{ text: this.$i18n.t('common.conservator'), value: 'owner' }] : []),
      { text: this.$i18n.t('common.created'), value: 'created' }
    ]
  }
}
